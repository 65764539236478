.monitoring-card {
    cursor: pointer;
    transition: background .5s;
}

.monitoring-card:hover {
    background-color: rgb(238, 238, 238);
}

.monitoring-card .p-card-title {
    display: flex;
    justify-content: space-between;
}

.monitoring-card .p-card-title .in-travel {
    font-size: 7pt;
    background-color: #689F38;
    padding: 0px 5px;
    border-radius: 2px;
    color: white;
    animation-name: blinking;
    animation-duration: 2s;
    animation-iteration-count: infinite;
}

.monitoring-card .p-card-title .in-deliver {
    font-size: 7pt;
    background-color: #ffe81b;
    padding: 0px 5px;
    border-radius: 2px;
    animation-name: blinking;
    animation-duration: 2s;
    animation-iteration-count: infinite;
}

.monitoring-card .p-card-title .man {
    font-size: 7pt;
    background-color: #b63434;
    padding: 0px 5px;
    border-radius: 2px;
    color: white;
}

.monitoring-card .p-card-title .aut {
    font-size: 7pt;
    background-color: #689F38;
    padding: 0px 5px;
    border-radius: 2px;
    color: white;
}

.monitoring-card .info-container {
    margin-bottom: 15px;
    border-bottom: 1px solid #ccc;
}

.monitoring-card .info-small {
    display: flex;
    flex-flow: column;
    justify-content: flex-end;
    border-right: 1px #ccc solid;
}

.monitoring-card .info-small:nth-last-child(1) {
    border-right: none;
}

.monitoring-card .info-small .title {
    text-align: center;
    font-size: 8pt;
    opacity: .6;
}

.monitoring-card .info-small .value {
    font-size: 11pt;
    text-align: center;
}

.monitoring-card .info-big {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
}

.monitoring-card .info-big .value {
    font-size: 25pt;
    font-weight: lighter;
}

.monitoring-card .info-big .title {
    font-size: 9pt;
}

.monitoring-card .data {
    font-size: 7pt;
    color: #6f6f6f;
}

@keyframes blinking {
    0%   {opacity: 1;}
    50%  {opacity: 0;}
    100% {opacity: 1;}
}

.leituras td {
    overflow: hidden;
    font-size: 9pt;
}

.leituras td:not(.left) {
    padding-right: 0px !important;
    padding-left: 0px !important;
    text-align: center;
}

.leituras td.small-text {
    font-size: 9px;
}

.leituras th {
    font-size: 8pt;
}

.row-alert {
    background-color: #ffd5d5 !important;
}

.alert-box {
    background-color: #fbc1c1;
    text-align: center;
    padding: 5px 0px;
    text-transform: uppercase;
    color: #800101;
    border-bottom: #800101 solid 1px;
}

.p-datatable-tbody td:empty {
    display: none;
}